import React from 'react';
import { VERSION } from '../components/configuracion/config';

function Footer() {
  return (
    <footer className="text-center bg-dark text-light py-3 mt-auto">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="mt-3 small">©{ new Date().getFullYear() }  Transportes Medellin Castilla. Todos los derechos reservados - version {VERSION} </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;



