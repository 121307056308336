import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify} from '../Funciones'


const ActualizarUsuario = ({
  mostrarModalActualizar,
  cerrarModalActualizar,
  actualizarModal,
  actualizaPassword,
  drwUsuario,
}) => {

  const [formulario, setFormulario] = useState({
    strUsuario: '',
    strNombres: '',
    strPassword: '',
    strTipo: '',
    bolActivo: '',
  });

  const [passwordFormulario, setPasswordFormulario] = useState({
    strPassword: '',
    strPasswordConfirm: ''
  });

  const [Tipo] = useState([
    { strTipo: 'admin', label: 'Administrador' },
    { strTipo: 'supervisor', label: 'Supervisor' },
    { strTipo: 'user', label: 'Usuario' }
  ]);

  const [Estado] = useState([
    { bolActivo: true,  label: 'Activo' },
    { bolActivo: false, label: 'Inactivo' },
  ]);

  useEffect(() => {
    if (drwUsuario) {
      setFormulario({
        ...drwUsuario,
      });
    }
  }, [mostrarModalActualizar, drwUsuario]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "bolActivo") {
      setFormulario((prevFormulario) => ({
        ...prevFormulario,
        [name]: value === "true",
      }));
    } else {
      setFormulario((prevFormulario) => ({
        ...prevFormulario,
        [name]: value,
      }));
    }
  };
  
  const handlePasswordInputChange = (event) => {
    const { name, value } = event.target;

    setPasswordFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };


  const closeModal = () => {

    //Limpia los campos antes de cerrar el modal
    setPasswordFormulario({
        strPassword: '',
        strPasswordConfirm: '',
    });
    cerrarModalActualizar();
  };

  const nombresRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const tipoRef = useRef();


  const validarActualizarModalUsuario = () => {

    const { strUsuario, strNombres, strTipo, bolActivo } = formulario;    

  // Check if there are any changes
  const hasChanges =
    strUsuario !== drwUsuario.strUsuario ||
    strNombres !== drwUsuario.strNombres ||
    strTipo !== drwUsuario.strTipo ||
    bolActivo !== drwUsuario.bolActivo;

  if (!hasChanges) {
    alertify.warning('No se ha realizado ningún cambio.');
    estiloAlertify(3);
    return;
  }

  if (!strNombres) {
      alertify.error('Por favor, Diligencie el nombre del usuario.');
      estiloAlertify(0);
      nombresRef.current.focus();
      return;
    }else if (!strTipo) {
      alertify.error('Por favor, seleccione el Tipo.');
      estiloAlertify(0);
      tipoRef.current.focus();
      return;
    } else if (bolActivo==='') {
      alertify.error('Por favor, seleccione el estado.');
      console.log(bolActivo);
      estiloAlertify(0);
      return;
    } else {

      alertify.confirm('¿Desea guardar los cambios',
        async (e) => {
          if (e) {          
            actualizarModal(formulario);     
          }
        }
      ).set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      })
    }
  };

  const validarActualizarModalPassword = () => {

    const {strPassword, strPasswordConfirm} = passwordFormulario;    


  if (!strPassword) {
      alertify.error('Por favor, digite el nuevo password.');
      estiloAlertify(0);
      passwordRef.current.focus();
      return;
    }else if (!strPasswordConfirm) {
      alertify.error('Por favor, confirme el password');
      estiloAlertify(0);
      passwordConfirmRef.current.focus();
      return;
    }     
    else if (strPassword !== strPasswordConfirm) {
      alertify.error('Por favor, debe coincidir con el password');
      estiloAlertify(0);
      passwordConfirmRef.current.focus();
      return;
    } 
    else {

      alertify.confirm('¿Desea guardar la nueva contraseña ?',
        async (e) => {
          if (e) {          
            actualizaPassword(passwordFormulario);   
          }
        }
      ).set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      })
    }
  };

  return (
    <Modal show={mostrarModalActualizar} onHide={closeModal}>
    <Modal.Header closeButton className="bg-secondary text-light ">
      <Modal.Title className="small">Editar Usuario</Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}>
   
    <div className="row">
            <div className="col-md-8">
              <form className="form-control bg-light text-black mt-2">
                <div className="form-group m-2">
                  <label htmlFor="strUsuario">ID:</label>
                  <input
                    type="text"
                    name="strUsuario"
                    id="strUsuario"
                    onChange={handleInputChange}
                    value={formulario.strUsuario}
                    className="form-control form-control-sm text-danger"
                    disabled
                  />
                </div>
                <div className="form-group m-2">
                  <label htmlFor="strNombres">Usuario</label>
                  <input
                    type="text"
                    name="strNombres"
                    id="strNombres"
                    onChange={handleInputChange}
                    value={formulario.strNombres}
                    ref={nombresRef}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group m-2">
                  <label htmlFor="strTipo">Tipo</label>
                  <select
                    name="strTipo"
                    id="strTipo"
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                    value={formulario.strTipo}
                  >
                    {Tipo.map((tipo) => (
                      <option key={tipo.strTipo} value={tipo.strTipo}>
                        {tipo.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group m-2">
                  <label htmlFor="bolActivo">Estado</label>
                  <select
                    name="bolActivo"
                    id="bolActivo"
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                    value={formulario.bolActivo}
                  >
                    {Estado.map((estado) => (
                      <option key={estado.bolActivo} value={estado.bolActivo}>
                        {estado.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group m-2 text-end">
                   <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm m-1"
                    onClick={() => validarActualizarModalUsuario()}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <form className="form-control bg-light text-black mt-2">
                <div className="form-group m-2">
                  <label htmlFor="strUsuario">Password:</label>
                  <input
                    type="Password"
                    name="strPassword"
                    id="strPassword"
                    onChange={handlePasswordInputChange}
                    ref={passwordRef}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group m-2 ">
                  <label htmlFor="strPasswordConfirm">Confirmar</label>
                  <input
                    type="Password"
                    name="strPasswordConfirm"
                    id="strPasswordConfirm"
                    onChange={handlePasswordInputChange}
                    ref={passwordConfirmRef}
                    className="form-control form-control-sm"
                  />
            
                </div>
                <div className="form-group m-2 text-end">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm m-1"        
                    onClick={() => validarActualizarModalPassword()}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
            </div>
    </Modal.Body>

    <Modal.Footer style={{ background: 'linear-gradient(to right, #0000, #85d3)' }}>
      <Button variant="outline-danger" className="btn btn-sm" onClick={closeModal}>
        Cerrar
      </Button>
    </Modal.Footer>

  </Modal>

  );
};

export default ActualizarUsuario;

