import Header from '../../Plantilla/Header';
import Footer from '../../Plantilla/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

function PDF(){
  const isPDFPluginAvailable = navigator.plugins && navigator.plugins.length > 0;

    return (
      <div style={{ flex: '1', minHeight: 'calc(100vh - 20px)' }}>
        <header>
          <Header />
        </header>
        {isPDFPluginAvailable ? (
          // Mostrar el PDF en un iframe si el plugin está disponible
          <iframe 
          className=''
            title="Guia PDF App Minutas"
            width="100%"
            height="900px"  
            src="/guia.pdf"  
           // src={require("../doc/guia.pdf")} en caso que no este en la carpeta publica                  
            
          ></iframe>
        ) : (
          // Proporcionar un enlace de descarga si el plugin no está disponible
          <div>
            <a
              href='/guia.pdf'
              target='_blank'
              rel='noopener noreferrer'
              download='guia.pdf'
              className='text-decoration-none text-reset mt-5'
            >
              <FontAwesomeIcon icon={faFile} size='2x' /> Descargar Guia PDF
            </a>
          </div>
        )}

        {/* Pie de página */}
        <footer>
         <Footer />
      </footer>
      </div>
    );
  
}
export default PDF;
