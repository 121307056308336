import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import api from '../configuracion/config';

const ConsultarAnotacion = ({
  mostrarModalConsultar,
  cerrarModalConsultar,
  drwAnotacion,
}) => {
  const [formulario, setFormulario] = useState({
    dteFecha: '',
    tmeHora: '',
    strAsunto: '',
    strAnotacion: '',
    strUsuario: '',
    strNombres: '',
    strSede: '',
  });

  const [sedes, setSedes] = useState([]);

  useEffect(() => {
    if (drwAnotacion) {
      setFormulario({
        ...drwAnotacion,
      });

      api
        .post('/Sedes')
        .then((response) => {
          setSedes(response.data);
        })
        .catch((error) => {
          console.error('Error al obtener las sedes:', error);
        });
    }
  }, [mostrarModalConsultar, drwAnotacion]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };

  const closeModal = () => {
    setFormulario({
      dteFecha: '',
      tmeHora: '',
      strAsunto: '',
      strAnotacion: '',
      strUsuario: '',
      strNombres: '',
      strSede: '',
    });
    cerrarModalConsultar();
  };

  return (
    <Modal show={mostrarModalConsultar} onHide={closeModal}>

    <Modal.Header closeButton className="bg-success text-light ">
      <Modal.Title className="small">Detalle Minuta</Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}>
    <form >
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-group ">
                    <label htmlFor="dteFecha">Fecha:</label>
                    <input 
                      type="date"
                      name="dteFecha"
                      id="dteFecha"
                      onChange={handleInputChange}
                      value={formulario.dteFecha}
                      className="form-control form-control-sm bg-light"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label htmlFor="tmeHora">Hora</label>
                    <input
                      type="time"
                      name="tmeHora"
                      id="tmeHora"
                      onChange={handleInputChange}
                      value={formulario.tmeHora}
                      className="form-control form-control-sm  bg-light"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mt-2">
                    <label htmlFor="strAsunto">Asunto</label>
                    <input
                      type="text"
                      name="strAsunto"
                      id="strAsunto"
                      onChange={handleInputChange}
                      value={formulario.strAsunto}
                      className="form-control form-control-sm  bg-light"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-2">
                    <label htmlFor="intIdSede">Sede</label>
                    <select disabled
                      name="intIdSede"
                      id="intIdSede"
                      onChange={handleInputChange}
                      className="form-control form-control-sm bg-light"
                      value={formulario.intIdSede}
                    >
                      {sedes.map((sede) => (
                        <option key={sede.intIdSede} value={sede.intIdSede}>
                          {sede.strSede}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mt-2">
                    <label htmlFor="strAnotacion">Anotación</label>
                    <textarea
                      name="strAnotacion"
                      id="strAnotacion"
                      onChange={handleInputChange}
                      value={formulario.strAnotacion}
                      className="form-control form-control-sm bg-light"
                      rows="5"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mt-2">
                    <label htmlFor="strUsuario">Usuario</label>
                    <input
                      type="text"
                      name="strUsuario"
                      id="strUsuario"
                      onChange={handleInputChange}
                      value={formulario.strUsuario}
                      className="form-control form-control-sm bg-light"
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-2">
                    <label htmlFor="strNombres">Nombres</label>
                    <input
                      type="text"
                      name="strNombres"
                      id="strNombres"
                      onChange={handleInputChange}
                      value={formulario.strNombres}
                      className="form-control form-control-sm bg-light"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>

    </Modal.Body>

    <Modal.Footer style={{ background: 'linear-gradient(to right, #0000, #85d3)' }} >
      <Button variant="outline-danger" className="btn btn-sm" onClick={closeModal}>
        Cerrar
      </Button>
    </Modal.Footer>

  </Modal>

  );
};

export default ConsultarAnotacion;
