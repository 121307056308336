import { useEffect } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {
  faDoorOpen,
  faPersonMilitaryPointing
} from '@fortawesome/free-solid-svg-icons';

function Header() {
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('userName');
  const userTipo = localStorage.getItem('userType');


  const estilo = {
    background: 'rgb(120, 50, 39)',
    padding: '0px',
    margin: '0px',
    color: 'black',
  };

  let tipoUsuario;

  if (userTipo === 'admin') 
  {
    tipoUsuario = 'Administrador';
  } 
  else if (userTipo === 'supervisor')  
  {
    tipoUsuario = 'Supervisor';
  }
  else{
    tipoUsuario = 'Usuario';
  }

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userId');
    navigate('/');
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (!userId) {
    return <Navigate to="/" />;
  }

  return (
    // <nav className="navbar navbar-expand-lg navbar-dark fixed-top"style={estilo}>
    <nav className="navbar navbar-expand-lg navbar-dark" style={estilo}>
      <div className="container">
        <Link className="navbar-brand nav-link" to="/inicio">
          {/* <FontAwesomeIcon icon={faHouseUser}  /> */}
          <img className='' alt='' src="/Logo.png" width="140" height="80"/>  
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarNav">
          <ul className="navbar-nav ">
            <li className="nav-item ">
              <Link className="nav-link" to="/minutas">
                Minutas
              </Link>
            </li>
            {userTipo === 'admin' && (
              <li className="nav-item">
                <Link className="nav-link" to="/usuarios">
                  Usuarios
                </Link>
              </li>
            )}
            {userTipo === 'admin' && (
              <li className="nav-item">
                <Link className="nav-link" to="/sedes">
                  Sedes
                </Link>
              </li>
            )}

            {(userTipo === 'admin' || userTipo === 'supervisor') && (
              <li className="nav-item">
                <Link className="nav-link" to="/informes">
                  Informes
                </Link>
              </li>
            )}
              {userTipo === 'admin' && (
              <li className="nav-item">
                <Link className="nav-link" to="/guia">
                  Guia
                </Link>
              </li>
            )}
          </ul>
          {/* Elemento "Perfil" en el extremo derecho */}
          <ul className="navbar-nav ms-auto mr-0">
            <li className="nav-item dropdown ">
              <a
                className="nav-link text-white "
                id="perfilDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                href="#."
              >
                <div className="text-center ">
                  <div >{userName} </div>
                  <div className="small">
                    {tipoUsuario}{' '}
                    <FontAwesomeIcon icon={faPersonMilitaryPointing} />
                  </div>
                </div>
              </a>
               <ul className="dropdown-menu bg-secondary " aria-labelledby="perfilDropdown">
        
                <li>
                  <button className="dropdown-item small bg-secondary  text-white" onClick={handleLogout}>
                      <FontAwesomeIcon icon={faDoorOpen} /> Salir
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
