import { useEffect } from 'react';


//FUNCION PARA PERSONALIZAR ALERTAS DE Alertify
export const estiloAlertify = (tipo = 0) => {
  let tipoMensaje;
  let color;
  let textColor;

  switch (tipo) {
    case 1: // Éxito
      tipoMensaje = document.querySelector('.ajs-success:last-child');
      color = 'rgb(20, 100, 50)';
      textColor = 'white';
      break;
    case 2: // Confirmación
      tipoMensaje = document.querySelector('.ajs-confirm:last-child');
      color = 'rgb(60, 60, 60)';
      textColor = 'white';
      break;
    case 3: // Advertencia (Warning)
      tipoMensaje = document.querySelector('.ajs-warning:last-child');
      color = 'rgb(60, 60, 60)';
      textColor = 'white'; 
      break;
    default: // Error
      tipoMensaje = document.querySelector('.ajs-error:last-child');
      color = 'rgb(120, 50, 39)';
      textColor = 'white';
  }

  if (tipoMensaje) {
    tipoMensaje.style.cssText = `
      background-color: ${color}; 
      color: ${textColor};
      border: none; 
      font-size: 14px; 
      text-align: center;
    `;
  }
};



//FUNCION PARA OBTENER LA HORA ACTUAL DEL SISTEMA
export const obtenerHoraActual = () => {
  const horaActual = new Date();
  let horas = horaActual.getHours();

  //horas = horas > 12 ? horas - 12 : horas;
  const minutos = horaActual.getMinutes();
  const segundos = horaActual.getSeconds();  

  return `${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
};


// FUNCION PARA PARA OBTENER LA HORA EN EJECUCION, TIEMPO REAL
export const HoraTiempoReal = ({ setHora }) => {
  useEffect(() => {
    const intervaloHora = setInterval(() => {
      const nuevaHora = obtenerHoraActual();
      setHora(nuevaHora);
    }, 1000);

    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(intervaloHora);
  }, [setHora]);

  return null; // Puedes devolver algo más si es necesario
};
