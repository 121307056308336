import axios from 'axios';

export const VERSION = '1.10';

// Configura la base de la URL de la API
const api = axios.create({
  //baseURL: 'http://localhost:4000' // Cambia esto a la URL de tu servidor Express
  baseURL: 'https://api.masmedellin.com/api-minuta',
});

export default api;
