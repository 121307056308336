import React, { useEffect, useState, useRef } from 'react';
import api from '../configuracion/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify} from '../Funciones'


const ActualizarAnotacion = ({ 
  mostrarModalActualizar, 
  cerrarModalActualizar, 
  actualizarModal, 
  drwAnotacion 
}) => 
{
  const [formulario, setFormulario] = useState({
    dteFecha: '',
    tmeHora: '',
    strAsunto: '',
    strAnotacion: '',
    intIdSede: '',
  });  

  const [sedes, setSedes] = useState([]);
  const [asuntos, setAsuntos] = useState([]);

  useEffect(() => {
    if (drwAnotacion) { 
      setFormulario({
        ...drwAnotacion,
      });

      api.post('/Sedes')
      .then((response) => {
        setSedes(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener las sedes:', error);
      });

      api.get('/Asuntos')
      .then((response) => {
        setAsuntos(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los Asuntos:', error);
      });

    }
  }, [mostrarModalActualizar, drwAnotacion]);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;    
    
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  }; 


  const closeModal = () => {
    setFormulario({
      dteFecha: '',
      tmeHora: '',
      strAsunto: '',
      strAnotacion: '',
      intIdSede: '',
    });
    cerrarModalActualizar();
  };

  const dteFechaRef = useRef();
  const tmeHoraRef = useRef();
  const strAsuntoRef = useRef();
  const strAnotacionRef = useRef();
  const intIdSedeRef = useRef();

  const validarActualizarModalInsertar = () => {

    const fechaActual = new Date().toISOString().split('T')[0];
    const { dteFecha, tmeHora, strAsunto, strAnotacion, intIdSede } = formulario;


  // Check if there are any changes
  const hasChanges =
    dteFecha !== drwAnotacion.dteFecha ||
    tmeHora !== drwAnotacion.tmeHora ||
    strAsunto !== drwAnotacion.strAsunto ||
    strAnotacion !== drwAnotacion.strAnotacion ||
    intIdSede !== drwAnotacion.intIdSede;

  if (!hasChanges) {
    alertify.warning('No se ha realizado ningún cambio.');
    estiloAlertify(3);
    return;
  }

    if (!dteFecha) {
      alertify.error('Por favor, seleccione la fecha.');
      dteFechaRef.current.focus();
      estiloAlertify(0);
      return;
    } else if (dteFecha > fechaActual) {
      alertify.error('No puede seleccionar una fecha posterior a la actual.');
      estiloAlertify(0);
      dteFechaRef.current.focus();
      return;
    } else if (!tmeHora) {
      alertify.error('Por favor, seleccione la hora.');
      estiloAlertify(0);
      tmeHoraRef.current.focus();
      return;
    } else if (!strAsunto) {
      alertify.error('Por favor, digite el Asunto.');
      estiloAlertify(0);
      strAsuntoRef.current.focus();
      return;
    } else if (intIdSede === '-1') {
      alertify.error('Por favor, seleccione la Sede.');
      estiloAlertify(0);
      intIdSedeRef.current.focus();
      return;
    } else if (!strAnotacion) {
      alertify.error('Por favor, digite la anotación.');
      estiloAlertify(0);
      strAnotacionRef.current.focus();
      return;
    } else {

      alertify.confirm('¿Desea guardar los cambios',
        async (e) => {
          if (e) {          
            actualizarModal(formulario);     
          }
        }
      ).set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      })
    }
  };
  
  return (
    <Modal show={mostrarModalActualizar} onHide={closeModal}>
    <Modal.Header closeButton className="bg-secondary text-light ">
      <Modal.Title className="small">Editar Minuta</Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}>
    <form className="mt-2">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="dteFecha">Fecha:</label>
                  <input
                    type="date"
                    name="dteFecha"
                    id="dteFecha"               
                    onChange={handleInputChange}
                     value= {formulario.dteFecha}
                    className="form-control form-control-sm "
                    ref={dteFechaRef}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="tmeHora">Hora</label>
                  <input
                    type="time"
                    name="tmeHora"
                    id="tmeHora"
                    onChange={handleInputChange}
                    value={formulario.tmeHora}
                    className="form-control form-control-sm "
                    ref={tmeHoraRef}
                  />
                </div>
              </div>
            </div>

            <div className="row">

              {/* <div className="col-md-6">
                <div className="form-group mt-2">
                  <label htmlFor="strAsunto">Asunto</label>
                  <input
                    type="text"
                    name="strAsunto"
                    id="strAsunto"
                    onChange={handleInputChange}
                    value={formulario.strAsunto}
                    className="form-control form-control-sm "
                    ref={strAsuntoRef}
                  />
                </div>
              </div> */}

              <div className="col-md-6">
                <div className="form-group mt-2">
                <label htmlFor="strAsunto">Asunto</label>
                <select
                    name="strAsunto"
                    id="strAsunto"
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                    value={formulario.strAsunto.trim()}
                    ref={strAsuntoRef}
                  >
                     {asuntos.map((asunto) => (
                      <option key={asunto.idAsunto} value={asunto.strAsunto}>
                        {asunto.strAsunto}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mt-2">
                <label htmlFor="intIdSede">Sede</label>
                <select
                    name="intIdSede"
                    id="intIdSede"
                    onChange={handleInputChange}
                    className="form-control form-control-sm"
                    value={formulario.intIdSede}
                    ref={intIdSedeRef}
                  >
                     {sedes.map((sede) => (
                      <option key={sede.intIdSede} value={sede.intIdSede}>
                        {sede.strSede}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <label htmlFor="strAnotacion">Anotación</label>
                  <textarea 
                    name="strAnotacion"
                    id="strAnotacion"
                    onChange={handleInputChange}
                    value={formulario.strAnotacion}
                    className="form-control form-control-sm"
                    rows="5"  
                    ref={strAnotacionRef}                 
                  />
                </div>
              </div>
            </div>
           
          </form>
    </Modal.Body>

    <Modal.Footer style={{ background: 'linear-gradient(to right, #0000, #85d3)' }}>
      <Button variant="outline-danger" className="btn btn-sm" onClick={closeModal}>
        Cerrar
      </Button>
      <Button variant="outline-secondary" className="btn btn-sm" onClick={validarActualizarModalInsertar}>
        Guardar
      </Button>
    </Modal.Footer>

  </Modal>

  );
};

export default ActualizarAnotacion; 
