import React, { useState, useEffect } from 'react';
import api from '../configuracion/config';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify } from '../Funciones';

const XLSX = require('xlsx');

function Filtro() {
  const [sedes, setSedes] = useState([]);
  const [anotaciones, setAnotaciones] = useState([]);

  const [formulario, setFormData] = useState({
    tmeHora: '',
    dteFecha: '',
    dteFecha2: '',
    strAsunto: '',
    intIdSede: -1,
    strAnotacion: '',
    strUsuario: '',
  });

  const cargarSedes = async () => {
    await api
      .post('/Sedes')
      .then((response) => {
        setSedes(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener las sedes:', error);
      });
  };

  const cargarFiltro = async () => {
    try {
      const filters = {};

      if (formulario.tmeHora) filters.tmeHora = formulario.tmeHora;

      if (formulario.dteFecha && formulario.dteFecha2) {
        filters.dteFecha = new Date(formulario.dteFecha);
        filters.dteFecha2 = new Date(formulario.dteFecha2);
      }

      if (formulario.strAsunto) filters.strAsunto = formulario.strAsunto;

      if (formulario.intIdSede !== -1 && formulario.intIdSede !== '-1')
        filters.intIdSede = formulario.intIdSede;

      if (formulario.strAnotacion)
        filters.strAnotacion = formulario.strAnotacion;

      if (formulario.strUsuario) filters.strUsuario = formulario.strUsuario;

      if (Object.keys(filters).length > 0) {
        const response = await api.post('/informes', filters);
        setAnotaciones(response.data);
      } else {
        //alert('Seleccione al menos un campo para realizar alguna Busqueda');
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const selectedDate = new Date(value);
    const currentDate = new Date(); // Obtiene la fecha actual

    // Compara la fecha seleccionada con la fecha actual
    if (
      (name === 'dteFecha' && selectedDate > currentDate) ||
      (name === 'dteFecha2' && selectedDate > currentDate)
    ) {
      alertify.error('No puedes seleccionar una fecha futura');
      estiloAlertify(0);
    } else {
      setFormData({ ...formulario, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formulario.dteFecha === '' && formulario.dteFecha2 !== '') {
      alertify.error('Debe Seleccionar Primero la Fecha Inicial');
      estiloAlertify(0);
      return;
    } else if (formulario.dteFecha2 === '' && formulario.dteFecha !== '') {
      alertify.error('Debe Seleccionar la Fecha Final');
      estiloAlertify(0);
      return;
    } else if (new Date(formulario.dteFecha) > new Date(formulario.dteFecha2)) {
      alertify.error(
        'La fecha Inicial debe ser menor o igual a la Fecha Final'
      );
      estiloAlertify(0);
      return;
    }
    cargarFiltro();
  };

  const limpiarFormulario = () => {
    setFormData({
      tmeHora: '',
      dteFecha: '',
      dteFecha2: '',
      strAsunto: '',
      intIdSede: -1,
      strAnotacion: '',
      strUsuario: '',
    });
    setAnotaciones([]);
  };

  useEffect(() => {
    cargarSedes();
  }, []);

  function exportToExcel() {
    if (anotaciones.length > 0) {
      alertify
        .confirm('¿Desea Exportar el informe a Excel?', async (e) => {
          if (e) {
            try {
              const dataForExcel = anotaciones.map((anotacion) => ({
                Fecha: format(
                  utcToZonedTime(parseISO(anotacion.dteFecha), 'UTC'),
                  'dd/MM/yyyy'
                ),
                Hora: format(
                  utcToZonedTime(parseISO(anotacion.tmeHora), 'UTC'),
                  'HH:mm:ss'
                ),
                Asunto: anotacion.strAsunto,
                Anotacion: anotacion.strAnotacion,
                Usuario: anotacion.strUsuario,
                Nombres: anotacion.strNombres,
                Sede: anotacion.strSede,
              }));

              const ws = XLSX.utils.json_to_sheet(dataForExcel);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Tabla de Datos');
              const excelBuffer = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'array',
              });

              const fileName = 'Minuta.xlsx';

              // Verificar si el archivo ya existe
              const existingFile = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              // Crear un enlace para descargar el archivo
              const a = document.createElement('a');
              a.href = URL.createObjectURL(existingFile);
              a.download = fileName;

              // Hacer clic en el enlace para descargar o reemplazar el archivo
              a.click();
            } catch (error) {
              alertify.error('Error al eliminar la minuta');
              estiloAlertify(0);
            }
          }
        })
        .set({
          labels: {
            ok: 'SI', // Etiqueta del botón OK
            cancel: 'NO', // Etiqueta del botón Cancelar
          },
          title: 'AVISO', // Título personalizado
        });
    } else {
      alertify.error(
        'la tabla Informes debe contener datos para poder exportar'
      );
      estiloAlertify(0);
    }
  }

  function exportToPDF() {
    if (anotaciones.length > 0) {
      alertify
        .confirm('¿Desea Exportar el informe a PDF?', async (e) => {
          if (e) {
            try {
              const doc = new jsPDF();
              const title = 'Informe Minuta';
              const date = 'Fecha: ' + new Date().toLocaleString();

              // Agregar título y fecha en la cabecera
              doc.setFontSize(16);
              doc.text(title, 15, 15);
              doc.setFontSize(10);
              doc.text(date, 15, 22);

              // Configura las columnas para la tabla
              const columns = [
                { title: 'Fecha', dataKey: 'Fecha' },
                { title: 'Hora', dataKey: 'Hora' },
                { title: 'Asunto', dataKey: 'Asunto' },
                { title: 'Anotacion', dataKey: 'Anotacion' },
                { title: 'Usuario', dataKey: 'Usuario' },
                { title: 'Nombres', dataKey: 'Nombres' },
                { title: 'Sede', dataKey: 'Sede' },
              ];
              doc.setFontSize(10);

              // Asegúrate de que dataForPDF sea un array de objetos
              const dataForPDF = anotaciones.map((anotacion) => ({
                Fecha: format(
                  utcToZonedTime(parseISO(anotacion.dteFecha), 'UTC'),
                  'dd/MM/yyyy'
                ),
                Hora: format(
                  utcToZonedTime(parseISO(anotacion.tmeHora), 'UTC'),
                  'HH:mm:ss'
                ),
                Asunto: anotacion.strAsunto,
                Anotacion: anotacion.strAnotacion,
                Usuario: anotacion.strUsuario,
                Nombres: anotacion.strNombres,
                Sede: anotacion.strSede,
              }));

              // Genera la tabla en el documento PDF
              doc.autoTable({
                columns,
                body: dataForPDF,
                head: [columns.map((column) => column.title)],
                startY: 30, // Ajusta la posición vertical de la tabla
                margin: { top: 20 }, // Ajusta el margen superior de la tabla
                tableLineColor: [0, 0, 0], // Color de las líneas de la tabla
                tableLineWidth: 0, // Grosor de las líneas de la tabla
                headStyles: {
                  fillColor: [204, 204, 204], // Color de fondo de la cabecera
                  textColor: [0, 0, 0], // Color de texto en la cabecera
                  fontSize: 8, // Tamaño de fuente en la cabecera
                },
                styles: {
                  fontSize: 8, // Tamaño de fuente para los datos de la tabla
                },
              });

              doc.save('Informe.pdf');
            } catch (error) {
              alertify.error('Error al eliminar la minuta');
              estiloAlertify(0);
            }
          }
        })
        .set({
          labels: {
            ok: 'SI', // Etiqueta del botón OK
            cancel: 'NO', // Etiqueta del botón Cancelar
          },
          title: 'AVISO', // Título personalizado
        });
    } else {
      alertify.error(
        'La tabla Informes debe contener datos para poder exportar'
      );
      estiloAlertify(0);
    }
  }

  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-md-2 ">
          <form className="form-control bg-light text-black">
            <div className="row mt-2">
              <div className="form-group small ">
                <label htmlFor="dteFecha">Fecha Inicial</label>
                <input
                  type="date"
                  name="dteFecha"
                  id="dteFecha"
                  className="form-control form-control-sm"
                  value={formulario.dteFecha}
                  onChange={handleChange}
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>

              <div className="form-group small mt-2">
                <label htmlFor="dteFecha2">Fecha Final</label>
                <input
                  type="date"
                  name="dteFecha2"
                  id="dteFecha2"
                  className="form-control form-control-sm"
                  value={formulario.dteFecha2}
                  onChange={handleChange}
                  max={new Date().toISOString().split('T')[0]}
                  required
                />
              </div>

              <div className="form-group small mt-2">
                <label htmlFor="tmeHora">Hora</label>
                <input
                  type="time"
                  name="tmeHora"
                  id="tmeHora"
                  className="form-control form-control-sm"
                  value={formulario.tmeHora} // Usa una cadena vacía si formData.tmeHora es null
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group small">
                <label htmlFor="strAsunto">Asunto</label>
                <input
                  type="text"
                  name="strAsunto"
                  id="strAsunto"
                  className="form-control form-control-sm"
                  value={formulario.strAsunto}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group small">
                <label htmlFor="strUsuario">Usuario</label>
                <input
                  type="text"
                  name="strUsuario"
                  id="strUsuario"
                  className="form-control form-control-sm"
                  value={formulario.strUsuario}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group small mt-2">
                <label htmlFor="intIdSede">Sede</label>
                <select
                  name="intIdSede"
                  id="intIdSede"
                  className="form-control form-control-sm"
                  value={formulario.intIdSede}
                  onChange={handleChange}
                >
                  <option value={-1}>Seleccionar</option>
                  {sedes.map((sede) => (
                    <option key={sede.intIdSede} value={sede.intIdSede}>
                      {sede.strSede}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="form-group small mt-2">
                <label htmlFor="strAnotacion">Anotación</label>
                <textarea
                  name="strAnotacion"
                  id="strAnotacion"
                  className="form-control form-control-sm"
                  rows="2"
                  value={formulario.strAnotacion}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row mt-1 ">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm  d-block m-1 "
                  onClick={limpiarFormulario}
                >
                  Limpiar
                </button>

                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm d-block m-1 "
                  onClick={handleSubmit}
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="col-md-10">
          <div className="row">
            <div className="col-8">
              <h5 className=" card-title text-secondary">Informes</h5>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <div className="btn-group " style={{ zIndex: '2' }}>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm  d-block m-1"
                  data-bs-toggle="dropdown"
                >
                  Exportar
                </button>
                <div className="dropdown-menu">
                  <button className="dropdown-item " onClick={exportToExcel}>
                    Exportar a Excel
                  </button>
                  <button className="dropdown-item" onClick={exportToPDF}>
                    Exportar a PDF
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid ps-0">
            <div className="row mt-2">
              <div className="table-responsive" style={{ maxHeight: '420px' }}>
                <table className="table  table-hover table-sm table-bordered">
                  <thead
                    className="table table-light small "
                    style={{ zIndex: '1' }}
                  >
                    <tr>
                      <th className="text-secondary  col-1">Fecha</th>
                      <th className="text-secondary  col-1">Hora</th>
                      <th className="text-secondary  ">Asunto</th>
                      <th className="text-secondary ">Anotacion</th>
                      <th className="text-secondary  col-1 text-center ">
                        Usuario
                      </th>
                      <th className="text-secondary  text-center">Nombres</th>
                      <th className="text-secondary  col-1 text-center">
                        Sede
                      </th>
                    </tr>
                  </thead>
                  <tbody className="small ">
                    {anotaciones.map((drw, index) => (
                      <tr key={index}>
                        <td className="small">
                          {format(
                            utcToZonedTime(parseISO(drw.dteFecha), 'UTC'),
                            'dd/MM/yyyy'
                          )}
                        </td>
                        <td className="small">
                          {format(
                            utcToZonedTime(parseISO(drw.tmeHora), 'UTC'),
                            'HH:mm:ss'
                          )}
                        </td>
                        <td className="small">{drw.strAsunto}</td>
                        <td className="small"> {drw.strAnotacion}</td>
                        <td className="small text-center">{drw.strUsuario}</td>
                        <td className="small text-center">{drw.strNombres}</td>
                        <td className="small text-center">{drw.strSede}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filtro;
