/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import api, { VERSION } from '../configuracion/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faKey
} from '@fortawesome/free-solid-svg-icons';

function Login() {
  const [Usuario, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const passwordInputRef = useRef(null);
  const loginButtonRef = useRef(null);

  const handleLogin = async () => {
    try {
      const response = await api.post('/login', {
        Usuario: Usuario,
        Password: Password,
      });

      if (response.status === 200) {
        setLoggedIn(true);
        localStorage.setItem('userId', response.data['strUsuario']);
        localStorage.setItem('userName', response.data['strNombres']);
        localStorage.setItem('userType', response.data['strTipo']);
      }
    } catch (err) {
      setError('Credenciales incorrectas. Por favor, inténtalo de nuevo.');
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target === passwordInputRef.current) {
        loginButtonRef.current.focus();
      } else {
        passwordInputRef.current.focus();
      }
    }
  };

  if (loggedIn) {
    return <Navigate to="/Inicio" />;
  }

  return (
    <div className="container mt-5 ">
      <style>
        {`
          body {
            background-color: rgb(200, 200, 170);
          }
          .icono {
            color:rgb(120, 50, 49);
          }
          .fondo {
            background-color:rgb(120, 50, 49);
            color:white;
          }
    
        `}
      </style>
      <div className="row justify-content-center ">
        <div className="col-md-7">
          <div className="card">
            <div className="card-header fondo">
              <div className="col-md-12">
                <img
                  src="./Logo.png"
                  className="img-fluid mx-auto d-block"
                  style={{ maxWidth: '150px' }}
                />
              </div>
            </div>
            <div className="card-body " style={{ background: 'linear-gradient(to right, #0000, #85d2)' }}>
              <div className="row">
                <div className="col-sm-6 d-flex justify-content-center align-items-center">
                  <p className="display-6 ">Minutas</p>
                </div>
                <div className="col-sm-6">
                  <form>

                    <div className="input-group  mt-3">
                      <span className="input-group-text icono">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="Usuario"
                        value={Usuario}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="ID del usuario"
                        onKeyPress={handleEnterPress}
                      />
                    </div>

                    <div className="input-group  mt-2">
                      <span className="input-group-text icono">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <input
                        type="password"
                        className="form-control"
                        id="Password"
                        value={Password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Clave del usuario"
                        ref={passwordInputRef}
                        onKeyPress={handleEnterPress}
                      />
                    </div>

                    <div className="d-grid mt-3">
                      <button
                        type="button"
                        className="btn  btn-block btn-sm btn-outline-secondary"
                        onClick={handleLogin}
                        ref={loginButtonRef}
                      >
                        Ingresar
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {error && <p className="icono mt-3">{error}</p>}
            </div>
            <div className="card-footer text-center m-0 p-0 ">
              <p className="small mt-2">
                ©{new Date().getFullYear()} version {VERSION}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
