import Header from '../../Plantilla/Header'; // Importa el componente Header
import Footer from '../../Plantilla/Footer'; // Importa el componente Footer
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filtro from './Filtro';
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';

const footerStyle = {
  position: 'fixed',
  bottom: 0,
  height:20,
  width: '100%',
  zIndex: 999, 
};

function TablaInformes() {

  const userTipo = localStorage.getItem('userType');  

  return (
    <div >
      <main >

      <header className=""><Header /></header>
      {userTipo === 'admin' || userTipo === 'supervisor' ? (
        <div className="container-fluid " style={{ margin:'0',  padding: '0'}}>
          <Filtro />
        </div>
      ) : (
        <div className="container " >
          <br/>
          <div className="row mt-5">
            <FontAwesomeIcon icon={faFaceFrown} beat size="4x" style={{ color: '#9aaf98' }}/>
          </div>
         

          <div className="row mt-3 text-center">
            <p className="text-secondary">Página no encontrada ...</p>
          </div>

        </div>
      )}

      <footer style={footerStyle}>
        <Footer/>
      </footer>    
      </main>
    </div>
            

  );
}

export default TablaInformes;
