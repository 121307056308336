import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/paginas/Login';
import Inicio from './components/paginas/Inicio';
import Minutas from './components/paginas/Minutas'; 
import Usuarios from './components/paginas/Usuarios'; 
import Informes from './components/paginas/Informes'; 
import Sedes from './components/paginas/Sedes'; 
import Guia from './components/paginas/Guia'; 


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/inicio" element={<Inicio />} /> 
         <Route path="/" element={<Login />} />     {/*  path="/" este es como el index */}
         <Route path="/minutas" element={<Minutas />} /> {/* Ruta para la página de Anotaciones */}
         <Route path="/usuarios" element={<Usuarios />} /> {/* Ruta para la página de Usuarios */}
         <Route path="/informes" element={<Informes />} /> {/* Ruta para la página de informes */}
         <Route path="/sedes" element={<Sedes />} /> {/* Ruta para la página de informes */}
         <Route path="/guia" element={<Guia />} /> {/* Ruta para la página de Guia */}
      </Routes>
    </Router>
  );
}

export default App;
