import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify} from '../Funciones'


const InsertarUsuario= ({
  mostrarModalInsertar,
  cerrarModalInsertar,
  guardarModalInsertar,
  
}) => {


  // Estado para los campos del formulario
  const [formulario, setFormulario] = useState({
    strUsuario: '',
    strNombres: '',
    strPassword: '',
    strTipo: '',
    bolActivo: '',
  });

  const usuarioRef = useRef(null);
  const nombresRef = useRef(null);
  const passwordRef = useRef(null);
  const tipoRef = useRef(null);
  const bolActivoRef = useRef(null);

  // Función para manejar cambios en los campos del formulario
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });
  };
  

  // Función para cerrar el modal y reiniciar el formulario
  const closeModal = () => {
    setFormulario({
        strUsuario: '',
        strNombres: '',
        strPassword: '',
        strTipo: '',
        bolActivo: '',
    });
    cerrarModalInsertar();
  };

  useEffect(() => {
    if (mostrarModalInsertar) {
      setFormulario({
        strUsuario: '',
        strNombres: '',
        strPassword: '',
        strTipo: '',
        bolActivo: '',
      });

    }
  }, [mostrarModalInsertar]);

  const validarGuardarModalInsertar= () => {
    
    const { strUsuario, strNombres, strPassword, strTipo, bolActivo } =  formulario;

    if (!strUsuario) {
      alertify.error('Por favor, Digite la Identificacion del Usuario.');
      estiloAlertify(0);
      usuarioRef.current.focus();
      return;
    } else if (!strNombres) {
      alertify.error('Por favor, Digite nombres.');
      estiloAlertify(0);
      nombresRef.current.focus();
      return;
    } else if (!strPassword) {
      alertify.error('Por favor, digite la Clave.');
      estiloAlertify(0);
      passwordRef.current.focus();
      return;
    } else if (!strTipo) {
      alertify.error('Por favor, seleccione el Tipo.');
      estiloAlertify(0);
      tipoRef.current.focus();
      return;
    } else if (!bolActivo) {
      alertify.error('Por favor, Seleccione el estado');
      estiloAlertify(0);
      bolActivoRef.current.focus();
      return;
    }else
    {
          alertify.confirm('¿Desea guardar este Usuario?',
          async (e) => {
            if (e) {          
                guardarModalInsertar(formulario);             
            }
          }
        ).set({
          labels: {
            ok: 'SI', // Etiqueta del botón OK
            cancel: 'NO', // Etiqueta del botón Cancelar
          },
          title: 'AVISO', // Título personalizado
        });
    }
  };
  const handleUsuarioKeyPress = (event) => {
    // Verificar si la tecla presionada no es un número
    if (isNaN(event.key) || event.key === ' ') {
      event.preventDefault(); // Prevenir la entrada de la tecla
    }
  };

  

  return (

    <Modal show={mostrarModalInsertar} onHide={closeModal}>
      <Modal.Header closeButton className="bg-success text-light ">
        <Modal.Title className="small">Nuevo Usuario</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}>
      <form >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label htmlFor="strUsuario">ID:</label>
                  <input
                    type="text"
                    name="strUsuario"
                    id="strUsuario"
                    onChange={handleInputChange}
                    onKeyPress={handleUsuarioKeyPress}
                    className="form-control form-control-sm "
                    ref={usuarioRef}
                    placeholder='Identificacion'
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label htmlFor="strNombres">Usuario</label>
                  <input
                    type="text"
                    name="strNombres"
                    id="strNombres"
                    onChange={handleInputChange}
                    className="form-control form-control-sm "
                    ref={nombresRef}
                    placeholder='Nombre de usuario'
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label htmlFor="strPassword">Password</label>
                  <input
                    type="password"
                    name="strPassword"
                    id="strPassword"
                    onChange={handleInputChange}
                    className="form-control form-control-sm "
                    ref={passwordRef}
                    placeholder='Clave de acceso'
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label htmlFor="strTipo">Tipo</label>
                  <select
                      name="strTipo"
                      id="strTipo"
                      onChange={handleInputChange}
                      className="form-control form-control-sm"
                      value={formulario.strTipo}
                      ref={tipoRef}
                    >
                      <option value="" >Seleccionar</option> 
                      <option value="user">Usuario</option>      
                      <option value="supervisor">Supervisor</option>
                      <option value="admin">Administrador</option>
                  
                                       
                    </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-2">
                  <label htmlFor="bolActivo">Estado</label>
                  <select
                      name="bolActivo"
                      id="bolActivo"
                      onChange={handleInputChange}
                      className="form-control form-control-sm"
                      value={formulario.bolActivo}
                      ref={bolActivoRef}
                    >
                      <option value="">Seleccionar</option>
                      <option value={true} >Activo</option>
                      <option value={false} >Inactivo</option>                    
                    </select>
                </div>
              </div>
            </div>          
            
          </form>
      </Modal.Body>

      <Modal.Footer style={{ background: 'linear-gradient(to right, #0000, #85d3)' }}>
        <Button variant="outline-danger" className="btn btn-sm" onClick={closeModal}>
          Cerrar
        </Button>
        <Button variant="outline-secondary" className="btn btn-sm" onClick={validarGuardarModalInsertar}>
          Guardar
        </Button>
      </Modal.Footer>

    </Modal>
  );


};

export { InsertarUsuario  };
