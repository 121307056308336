import Header from '../../Plantilla/Header'; // Importa el componente Header
import Footer from '../../Plantilla/Footer'; // Importa el componente Footer
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../configuracion/config';
import ConsultarAnotacion from '../modales/modalConsultarMinutas';
import { InsertarAnotacion } from '../modales/modalInsertarMinutas';
import ActualizarAnotacion from '../modales/modalActualizarMinutas';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify } from '../Funciones';

import {
  faPlus,
  faMagnifyingGlass,
  faFilePen,
  faEye,
  faTrashCan,
  faUserSlash
} from '@fortawesome/free-solid-svg-icons';

function TablaAnotaciones() {
  const [anotacion, setAnotacion] = useState([]);
  const strUsuario = localStorage.getItem('userId');
  const itemsPerPage = 10;
  const pageCount = Math.ceil(anotacion.length / itemsPerPage);
  const [searchInput, setSearchInput] = useState('');
  const [mostrarModalConsultar, setMostrarModalConsultar] = useState(false);
  const [mostrarModalInsertar, setMostrarModalInsertar] = useState(false);
  const [mostrarModalActualizar, setMostrarModal] = useState(false);
  const [drwAnotacion, setDrwAnotacion] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const userTipo = localStorage.getItem('userType');

  const fetchData = async () => {
    try {
      if (userTipo === 'admin' || userTipo === 'supervisor') {
        const response = await api.get('/anotaciones');
        if (response.status === 200) {
          setAnotacion(response.data);
        }
      } else {
        const response = await api.post('/anotacion', { Usuario: strUsuario });
        if (response.status === 200) {
          setAnotacion(response.data);
        }
      }
    } catch (error) {
      console.error('Error al obtener las anotaciones:', error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    setPageNumber(0);
  };

  const funcionMostrarModalInsertar = () => {
    setMostrarModalInsertar(true);
  };

  const funcionCerrarModalInsertar = () => {
    setMostrarModalInsertar(false);
  };

  const funcionGuardarAnotacion = async (formulario) => {
    const { dteFecha, tmeHora, strAsunto, strAnotacion, intIdSede } =
      formulario;
    const dttAnotacion = {
      dteFecha,
      tmeHora,
      strAsunto,
      strAnotacion,
      strUsuario: strUsuario,
      intIdSede,
    };

    try {
      //#REGION: Con validacion JavaScript
      // const confirmar = window.confirm(
      //   '¿ Desea guardar ésta Minuta ?'
      // );

      // if (confirmar) {
      //     const response = await api.post('/InsertarAnotacion', dttAnotacion);
      //     if (response.status === 200) {
      //         fetchData(strUsuario);
      //         funcionCerrarModalInsertar();
      //        }
      // }
      // #ENDREGION

      const response = await api.post('/InsertarAnotacion', dttAnotacion);
      if (response.status === 200) {
        fetchData(strUsuario);
        funcionCerrarModalInsertar();
        alertify.success('Guardado');
        estiloAlertify(1);
      }
    } catch (error) {
      console.error('Error al obtener las anotaciones:', error);
      alertify.error('Ocurrió un error al guardar la Minuta.');
      estiloAlertify(0);
    }
  };

  const funcionEliminarAnotacion = async (idAnotacion) => {
    alertify
      .confirm('¿Desea eliminar ésta Minuta?', async (e) => {
        if (e) {
          try {
            const response = await api.delete(
              `/EliminarAnotacion/${idAnotacion}`
            );
            if (response.status === 200) {
              setAnotacion(
                anotacion.filter((item) => item.idAnotacion !== idAnotacion)
              );
              fetchData();
              alertify.success('Eliminado. ');
              estiloAlertify(1);
            }
          } catch (error) {
            alertify.error('Error al eliminar la minuta');
             estiloAlertify(0);
          }          
        }
      })
      .set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      });
    
  };

  const funcionMostrarModalConsultar = (anotacion) => {
    const fechaISO = format(
      utcToZonedTime(parseISO(anotacion.dteFecha, 'dd/MM/yyyy'), 'UTC'),
      'yyyy-MM-dd'
    );
    const horaFormateada = format(
      utcToZonedTime(parseISO(anotacion.tmeHora, 'HH:mm:ss'), 'UTC'),
      'HH:mm:ss'
    );

    setDrwAnotacion({
      ...anotacion,
      dteFecha: fechaISO,
      tmeHora: horaFormateada,
    });

    setMostrarModalConsultar(true);
  };
  const funcionCerrarModalConsultar = () => {
    setMostrarModalConsultar(false);
  };

  const funcionMostrarModalActualizar = (anotacion) => {
    const fechaISO = format(
      utcToZonedTime(parseISO(anotacion.dteFecha, 'dd/MM/yyyy'), 'UTC'),
      'yyyy-MM-dd'
    );
    const horaFormateada = format(
      utcToZonedTime(parseISO(anotacion.tmeHora, 'HH:mm:ss'), 'UTC'),
      'HH:mm:ss'
    );

    setDrwAnotacion({
      ...anotacion,
      dteFecha: fechaISO,
      tmeHora: horaFormateada,
    });

    setMostrarModal(true);
  };

  const funcionCerrarModalActualizar = () => {
    setMostrarModal(false);
  };

  const funcionActualizarAnotacion = async (anotacionActualizar) => {
    if (anotacionActualizar.idAnotacion) {
      try {
        const response = await api.put(
          `/ActualizarAnotacion/${anotacionActualizar.idAnotacion}`,
          anotacionActualizar
        );
        if (response.status === 200) {
            alertify.success('Guardado.');
            estiloAlertify(1);

          fetchData();
          funcionCerrarModalActualizar();
        }
      } catch (error) {
        console.error('Error al actualizar la anotación:', error);
        alertify.error('Ocurrió un error al guardar la Minuta.');
      }
    }
  };

  // Lógica para aplicar el filtro
  const filteredData = anotacion.filter((drw) => {
    const formattedSearchInput = searchInput.toLowerCase();
    const sedeMatch = drw.strSede.toLowerCase().includes(formattedSearchInput);
    const AsuntoMatch = drw.strAsunto
      .toLowerCase()
      .includes(formattedSearchInput);
    const fecha = utcToZonedTime(parseISO(drw.dteFecha), 'UTC');
    const hora = utcToZonedTime(parseISO(drw.tmeHora), 'UTC');
    const formattedDate = format(fecha, 'dd/MM/yyyy');
    const formattedTime = format(hora, 'HH:mm');
    const fechaMatch = formattedDate.includes(formattedSearchInput);
    const horaMatch = formattedTime.includes(formattedSearchInput);
    return sedeMatch || fechaMatch || AsuntoMatch || horaMatch;
  });

  // Lógica para la paginación
  const offset = pageNumber * itemsPerPage;
  const paginatedData = filteredData.slice(offset, offset + itemsPerPage);

  return (
    <div >
      <style>
        {`
            .pagination-container {
                display: flex;
                justify-content: flex-end;
            }
            .pagination {
              display: inline-block;
              list-style-type: none;
              margin: 0;
              padding: 0;
            }
            .pagination li {
              display: inline;
            }
            .pagination a {
              color: black;
              float: left;
              padding: 0px 3px;
              text-decoration: none;
            }
            .pagination .active a {
              background-color: gray;
              color: white;
              padding: 0px 6px;
            }
            .tablescroll {
              overflow-y: auto;
              //height: 390px;
            }         
            
    `}
      </style>
      <header>
        <Header />
      </header>

      <main
        className=" container-fluid mt-2" style={{ flex: '1', minHeight: 'calc(100vh - 20px)' }}
      >
        <div className="card">
          <div className="card-header ">
            <div className="row">
              <div className="col-md-8 ">
                <h5 className="card-title text-secondary">Minutas</h5>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-sm">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Fecha, Hora, Asunto, Sede"
                    value={searchInput}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-1 col-sm-12 text-md-end text-sm-start mt-2 mt-sm-0">
                <button
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={funcionMostrarModalInsertar}
                >
                  <FontAwesomeIcon icon={faPlus} /> Nueva
                </button>
              </div>
            </div>
          </div>
          <div className="card-body ">
            <div className="table-responsive tablescroll d-sm-block">
              <table className="table  table-hover table-sm table-bordered">
                <thead className="table table-light ">
                  <tr>
                    <th className="col-1 small text-secondary ">Fecha</th>
                    <th className="col-1 small text-secondary ">Hora</th>
                    <th className="small text-secondary ">Asunto</th>
                    <th className="small text-secondary ">Anotacion</th>
                    <th className="small  text-center text-secondary ">Sede</th>
                    {(userTipo === 'admin' || userTipo === 'supervisor') && (
                      <th className="small text-center text-secondary ">
                        Usuario
                      </th>
                    )}
                    {(userTipo === 'admin' || userTipo === 'supervisor') && (
                      <th className="small text-center text-secondary ">
                        Nombres
                      </th>
                    )}
                    {(userTipo === 'admin' || userTipo === 'supervisor') && (
                      <th className="small col-1 text-center text-white bg-success">
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="small align-middle">
                  {paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="7">No hay registros</td>
                    </tr>
                  ) : (
                    paginatedData.map((drw) => (
                      <tr key={drw.idAnotacion}>
                        <td className="d-none">{drw.idAnotacion}</td>
                        <td className="small">
                          {format(
                            utcToZonedTime(parseISO(drw.dteFecha), 'UTC'),
                            'dd/MM/yyyy'
                          )}
                        </td>
                        <td className="small">
                          {format(
                            utcToZonedTime(parseISO(drw.tmeHora), 'UTC'),
                            'HH:mm:ss'
                          )}
                        </td>
                        <td className="small">{drw.strAsunto}</td>
                        <td className="small">{drw.strAnotacion}</td>
                        <td className="small  text-center ">{drw.strSede}</td>
                        {(userTipo === 'admin' ||
                          userTipo === 'supervisor') && (
                          <td className="small  text-center ">
                            {drw.strUsuario}
                          </td>
                        )}
                        {(userTipo === 'admin' ||
                          userTipo === 'supervisor') && (
                            <td className="small text-center">
                              {drw.strNombres === null ? (               
                                <span className ='text-danger'> <FontAwesomeIcon icon={faUserSlash} /> Eliminado</span>
                              ) : (     
                                <span>{drw.strNombres}</span>
                              )}
                          </td>
                        )}
                        {userTipo === 'admin' && (
                          <td className="text-center">
                            <div
                              className="btn-group "
                              role="group"
                              aria-label=""
                            >
                              <button
                                className="btn  btn-sm ps-0"
                                onClick={() =>
                                  funcionMostrarModalConsultar(drw)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="text-success"
                                />
                              </button>

                              <button
                                className="btn  btn-sm ps-0"
                                onClick={() =>
                                  funcionMostrarModalActualizar(drw)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faFilePen}
                                  className="text-secondary"
                                />
                              </button>
                              <button
                                className="btn  btn-sm  ps-0"
                                onClick={() =>
                                  funcionEliminarAnotacion(drw.idAnotacion)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  className="text-danger"
                                />
                              </button>
                            </div>
                          </td>
                        )}

                        {userTipo === 'supervisor' && (
                          <td className="text-center">
                            <div
                              className="btn-group"
                              role="group"
                              aria-label=""
                            >
                              <button
                                className="btn btn-sm  "
                                onClick={() =>
                                  funcionMostrarModalConsultar(drw)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="text-success"
                                />
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer">
            <div className="row">
              <div className="col-md-8">
                {/* Contenido de la primera columna */}
              </div>
              <div className="col-md-4 text-end small">
                <ReactPaginate
                  previousLabel={'Anterior'}
                  nextLabel={'Siguiente'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
          
        </div>
        <div>
          <InsertarAnotacion
            mostrarModalInsertar={mostrarModalInsertar}
            cerrarModalInsertar={funcionCerrarModalInsertar}
            guardarModalInsertar={funcionGuardarAnotacion}
          />
          {drwAnotacion && (
            <ActualizarAnotacion
              mostrarModalActualizar={mostrarModalActualizar}
              cerrarModalActualizar={funcionCerrarModalActualizar}
              actualizarModal={funcionActualizarAnotacion}
              drwAnotacion={drwAnotacion}
            />
          )}
          {drwAnotacion && (
            <ConsultarAnotacion
              mostrarModalConsultar={mostrarModalConsultar}
              cerrarModalConsultar={funcionCerrarModalConsultar}
              drwAnotacion={drwAnotacion}
            />
          )}
        </div>
       
      </main>

      <footer>
     < Footer/>
    </footer>
    </div>
  );
}

export default TablaAnotaciones;

