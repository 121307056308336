import { VERSION } from '../configuracion/config';
import Header from '../../Plantilla/Header'; // Importa el componente Header

//background-color: rgb(120, 50, 39);
function Inicio() {
  return (
    <div>
      <style>
        {`
          body {
           background-color: rgb(120, 50, 39);
          }
        `}
      </style>
      <Header />
      <br/><br/>
      <div className="p-5 text-black  text-white text-center">   
        <div className="container mt-5" >
       
          <div className="col-md-6 col-sm-6 col-lg-12">                
            <dl>
              <dt className="display-4 "> App Minutas </dt>
              <dd className="small mt-2" >Gestión y Diligenciamiento de minutas</dd>
            </dl>
          </div>
        </div>
      </div>

      {/* Div de footer */}
      <div className="footer">
      <footer className="text-center bg-dark text-light py-3 position-fixed bottom-0 w-100">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <p className="mt-3 small">©{new Date().getFullYear()} Transportes Medellin Castilla. Todos los derechos reservados - version {VERSION}</p>
          </div>
        </div>
      </div>
    </footer>
      </div>
    </div>
  );
}

export default Inicio;
