import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';
import { estiloAlertify } from '../Funciones';

const ActualizarSede = ({
  mostrarModalActualizar,
  cerrarModalActualizar,
  actualizarModal,
  drwSede,
}) => {
  const [formulario, setFormulario] = useState({
    intIdSede: '',
    strSede: '',
    strDescripcion: '',
  });

  useEffect(() => {
    if (drwSede) {
      setFormulario({
        ...drwSede,
      });
    }
  }, [mostrarModalActualizar, drwSede]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };

  const closeModal = () => {
    setFormulario({
      strSede: '',
      strDescripcion: ''
    });
    cerrarModalActualizar();
  };
   
  const strSedeRef = useRef();
  const strDescripcionRef = useRef();

  const validarModalActualizar= () => {

    const { strSede, strDescripcion} = formulario;

  // Check if there are any changes
  const tieneCambios =
    strSede !== drwSede.strSede ||
    strDescripcion !== drwSede.strDescripcion;

  if (!tieneCambios) {
    alertify.warning('No se ha realizado ningún cambio.');
    estiloAlertify(3);
    return;
  }
 if (!strSede) {
      alertify.error('Por favor, digite la Sede.');
      estiloAlertify(0);
      strSedeRef.current.focus();
      return;
    } else if (!strDescripcion) {
      alertify.error('Por favor, digite la descripcion.');
      estiloAlertify(0);
      strDescripcionRef.current.focus();
      return;
    } 
    else {

      alertify.confirm('¿Desea guardar los cambios',
        async (e) => {
          if (e) {          
            actualizarModal(formulario);     
          }
        }
      ).set({
        labels: {
          ok: 'SI', // Etiqueta del botón OK
          cancel: 'NO', // Etiqueta del botón Cancelar
        },
        title: 'AVISO', // Título personalizado
      })
    }
  };

  return (

    <Modal show={mostrarModalActualizar} onHide={closeModal}>
      <Modal.Header closeButton className="bg-secondary text-light ">
        <Modal.Title className="small">Editar Sede</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ background: 'linear-gradient(to right, #0000, #85d4)' }}>
      <form >
                  <div className="form-group m-2">
                    <label htmlFor="strSede">ID:</label>
                    <input
                      type="text"
                      name="intIdSede"
                      id="intIdSede"
                      onChange={handleInputChange}
                      value={formulario.intIdSede}
                      className="form-control form-control-sm text-danger"
                      disabled
                   
                    />
                  </div>
                  <div className="form-group m-2">
                    <label htmlFor="strSede">Sede:</label>
                    <input
                      type="text"
                      name="strSede"
                      id="strSede"
                      onChange={handleInputChange}
                      value={formulario.strSede}
                      className="form-control form-control-sm"
                    />
                  </div>
                  <div className="form-group m-2">
                    <label htmlFor="strDescripcion">Descripcion</label>
                    <input
                      type="text"
                      name="strDescripcion"
                      id="strDescripcion"
                      onChange={handleInputChange}
                      value={formulario.strDescripcion}
                      className="form-control form-control-sm"
                    />
                  </div>

                 </form>
      </Modal.Body>

      <Modal.Footer style={{ background: 'linear-gradient(to right, #0000, #85d3)' }} >
        <Button variant="outline-danger" className="btn btn-sm" onClick={closeModal}>
          Cerrar
        </Button>
        <Button variant="outline-secondary" className="btn btn-sm"  onClick={() => validarModalActualizar()}>
          Guardar
        </Button>
      </Modal.Footer>

    </Modal>
  );

};

export default ActualizarSede;
